import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';

export default class Button extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			text: this.props.text,
			onPress: this.props.onPress,
			disabled: this.props.disabled || false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.disabled) {
			this.setState({disabled: nextProps.disabled});
		}
	}

	render () {
		return (
			<TouchableOpacity disabled={this.state.disabled} onPress={this.state.onPress}>
        <View style={[styles.button, this.props.style]}>
	    	  <Text style={[styles.text, this.props.textStyle]}>{this.state.text}</Text>
        </View>
	    </TouchableOpacity>
		)
	}
}

const styles = StyleSheet.create({
	button: {
    backgroundColor: Colors.button,
    height: Layout.buttonHeight,
    width: Layout.buttonWidth,
    marginVertical: 5,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
    borderRadius: 30,
    paddingHorizontal: 15
	},
  text: {
    textAlign: 'left',
    color: Colors.defaultText,
    fontSize: 18
  }
});
