import React from 'react';
import {
  StyleSheet,
  View,
  TextInput
} from 'react-native';
import SecondaryHeader from '../components/SecondaryHeader';
import { HR } from '../components/HR';
import Button from '../components/Button';
import Smile from '../components/Smile';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';

export default class FeedbackScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: '',
      placeholder: 'Your words are welcome here.',
      smileSelected: false,
      mehSelected: true,
      frownSelected: false,
      focus: false,
      emotion: 'Neutral',
      handleSubmitFeedback: this.props.handleSubmitFeedback.bind(this)
    }
  }

  _handleSmilePress = (smile) => {
    console.log(smile)
    this.setState({ smileSelected: false, mehSelected: false, frownSelected: false });
    switch(smile) {
      case 'smile-o':
        this.setState({ smileSelected: true, emotion: 'Positive' });
        break;
      case 'meh-o':
        this.setState({ mehSelected: true, emotion: 'Neutral' });
        break;
      case 'frown-o':
        this.setState({ frownSelected: true, emotion: 'Negative' });
        break;
      default:
        break;
    }
  }

  _handleSubmit() {
    console.log("inputText: " + this.state.inputText);
    this.setState({ focus: false });
    this.state.handleSubmitFeedback(this.state.inputText, this.state.emotion);
  }

  _handleFocus = (event) => {
    console.log("FOCUS")
    /*if (this.state.inputText === "") {
      // first type
      this.setState({ focus: true });
    }*/
  }

  render() {
    return (
      <View style={styles.container}>
        {
          (!this.state.focus) ? (
            <View>
              <SecondaryHeader style={styles.header}>Tell Us Your Pulse</SecondaryHeader>
              <View style={styles.smiles}>
                <Smile name='smile' onPress={this._handleSmilePress.bind(this, 'smile-o')} selected={this.state.smileSelected}/>
                <Smile name='meh' onPress={this._handleSmilePress.bind(this, 'meh-o')} selected={this.state.mehSelected}/>
                <Smile name='frown' onPress={this._handleSmilePress.bind(this, 'frown-o')} selected={this.state.frownSelected}/>
              </View>
              <HR />
            </View>
          ) : null
        }
        <View style={styles.inputViewContainer}>
          <View style={styles.inputContainer}>
            <TextInput
    					style={styles.input}
              onFocus={this._handleFocus.bind(this)}
    					onChangeText={(text) => {this.setState({ inputText: text })}}
    					value={this.state.inputText}
    					placeholder={this.state.placeholder}
              multiline
          		underlineColorAndroid={'transparent'}
    				/>
          </View>
          <Button text="Submit" style={styles.submitButton} textStyle={styles.submitButtonText} onPress={() => { this._handleSubmit() }}/>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  header: {
    marginBottom: Layout.contentPaddingTopL,
  },
  smiles: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: Layout.contentPaddingTopL
  },
  container: {
    flex: 1,
    justifyContent: 'space-between'
  },
  input: {
    flex: 1,
    fontSize: Layout.pText,
    color: Colors.defaultText,
    paddingHorizontal: '.5em',
    paddingTop: '.5em',
	  backgroundColor: 'white',
    borderColor: Colors.border,
    borderWidth: '.5px',
    textAlignVertical: 'top',
    overflow: 'scroll',
    height: '10em',
    width: '15em'
  },
  inputViewContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: Layout.contentPaddingTopL
  },
  inputContainer: {
    flexDirection: 'row'
  },
  submitButton: {
    marginTop: Layout.contentPaddingTopXL,
    justifyContent: 'center',
    backgroundColor: Colors.submitButton
  },
  submitButtonText: {
    color: Colors.defaultText
  }
});
