import React from 'react';
import { Text } from 'react-native';

export class OriyaText extends React.Component {
  render() {
    return <Text {...this.props} style={[this.props.style, { fontFamily: 'OriyaSangamMN' }]} />;
    /*
    Optional fonts:
    - KohinoorBangla-Regular
    - DevanagariSangamMN
    - OriyaSangamMN
    */
  }
}
