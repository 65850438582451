export default {
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeText: '#fff',
  header: "#24D2A9",
  hr: "rgba(36,210,169,.8)", //"rgba(140,140,140,.3)",
  border: 'rgba(140,140,140,.2)',
  buttonLighter: "rgba(140,140,140,.05)",
  defaultTextLighter: '#9b9b9b',
  defaultText: "#6a6a6a",
  defaultTextDarker: "#484848",
  button: "rgba(140,140,140,.07)", //"rgba(36,210,169,0.08)"",
  smile: "rgba(245,245,245,1)",
  pulse: "#ff6b83",
  submitButton: "rgba(36,210,169,.1)",
  electricblue: '#0061ff'
};
