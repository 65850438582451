const config = {
  Storage: {
    AWSS3: {
        bucket: 'students-items', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-1', //OPTIONAL -  Amazon service region
    }
  },
  Auth: {
    userPoolId: "us-east-1_14HmDqXFT",
    region: "us-east-1",
    userPoolWebClientId: "2djfmequmfsl148rjkn1tv7sej",
    identityPoolId: "us-east-1:9dde35db-97de-47bb-b593-76adec110d0b",
    mandatorySignIn: false,
  },
  "aws_appsync_graphqlEndpoint": "https://rckthopz2ncszodxmxcltrm4li.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AWS_IAM",
  "env": "students"
}

export default config;
