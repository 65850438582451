// eslint-disable
// this is an auto generated file. This will be overwritten

export const getResource = `query GetResource($id: ID!) {
  getResource(id: $id) {
    id
    name
    details {
      id
      name
      displayName
      value
      images {
        id
        uri
        caption
      }
      links {
        id
        url
      }
      html
      index
    }
    createdAt
    updatedAt
    active
  }
}
`;
export const listResources = `query ListResources(
  $filter: ModelResourceFilterInput
  $limit: Int
  $nextToken: String
) {
  listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      details {
        id
        name
        displayName
        value
        images {
          id
          uri
          caption
        }
        links {
          id
          url
        }
        html
        index
      }
      createdAt
      updatedAt
      active
    }
    nextToken
  }
}
`;
export const getFeedback = `query GetFeedback($id: ID!) {
  getFeedback(id: $id) {
    id
    anonymous
    emotion
    text
    user
  }
}
`;
export const listFeedbacks = `query ListFeedbacks(
  $filter: ModelFeedbackFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      anonymous
      emotion
      text
      user
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    phoneNumber
    givenName
    familyName
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      phoneNumber
      givenName
      familyName
    }
    nextToken
  }
}
`;
