import React from 'react';
import {
  ScrollView,
  StyleSheet,
  View,
  ActivityIndicator
} from 'react-native';
import uuid from 'uuid/v4';

import { OriyaText } from '../components/StyledText';
import TopHeader from '../components/TopHeader';
import { HR } from '../components/HR.js';
import Pulse from '../components/Pulse';
import Button from '../components/Button.js';
import BackButton from '../components/BackButton.js';
import ResourceDetailScreen from '../screens/ResourceDetailScreen.js';
import FeedbackScreen from '../screens/FeedbackScreen.js';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';

import API, { graphqlOperation } from "@aws-amplify/api";
import { Logger } from 'aws-amplify';
import Storage from '@aws-amplify/storage';
import awsconfig from '../aws-configs';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';

API.configure(awsconfig);
const logger = new Logger('logger', 'DEBUG');

const RESOURCE_ORDER_FILE = `resource_order_${awsconfig.env}.json`;

export default class HomeScreen extends React.Component {
  static navigationOptions = {
    header: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      renderResourceDetail: false,
      renderFeedbackPage: false,
      resources: [],
      loadingResources: true,
      user: null,
      images: {}
    }

    this._loadImages = this._loadImages.bind(this);
  }

  _loadImages(detail) {
    detail.images.map(async (image, index) => {
      const src = await Storage.get(image.uri);
      if (src) {
        let newImages = Object.assign({}, this.state.images);
        newImages[image.uri] = src;
        this.setState({images: newImages});
      }
    })
  }

  async componentDidMount() {
    /*let resourceObject = {
        id: '12345',
        name: 'test',
        createdAt: new Date().toISOString(),
        active: true,
        details: [{
            id: uuid(),
            name: "etsy-shop",
            displayName: "Etsy Shop",
            value: null,
            images: null,
            links: [{id: uuid(), url: "https://etsy.com/shop/LauraLikesArt"}],
            html: null,
            index: 0
        }]
      }
      await API.graphql(graphqlOperation(mutations.createResource, { input: resourceObject }));*/
    try {
      const resources = await API.graphql(graphqlOperation(queries.listResources));
      if (resources.data.listResources.items.length === 0) {
        console.log("no data");
        this.setState({ loadingResources: false });
      } else {
        let resourceOrder;
        Storage.get(RESOURCE_ORDER_FILE, { download: true }).then(async (result) => {
          if (result.Body.length) {
            resourceOrder = JSON.parse(result.Body.toString());
            // const resources = await API.graphql(graphqlOperation(queries.listResources));
            this.setState({ loadingResources: false });
            if (resources) {

              const _sortResources = (r1, r2) => {
                if (!resourceOrder[r1.id] && !resourceOrder[r2.id]) {
                  return Date.parse(r2.createdAt) - Date.parse(r1.createdAt);
                } else if (!resourceOrder[r1.id]) {
                  return -1;
                } else if (!resourceOrder[r2.id]) {
                  return 1;
                } else {
                  return resourceOrder[r1.id] - resourceOrder[r2.id];
                }
              }

              let resourceList = resources.data.listResources.items.slice().sort(_sortResources).sort(_sortResources);
              resourceList.map((resource, index) => {
                resource.details.map((detail, index) => {
                  if (detail.images) {
                    this._loadImages(detail);
                  }
                  return 0;
                })
                return 0;
              });

              this.setState({resources: resourceList, loadingResources: false});
            }
          } else {
            console.log("no order");
            this.setState({resources: resources.data.listResources.items, loadingResources: false});
          }

        }).catch((err) => {
          console.log(err);
        });
      }
    } catch (err) {
      console.log('error listing resource... ')
      logger.debug('callback data', err);
      console.log(err);
    }
    try {
      const subscription = API.graphql(graphqlOperation(subscriptions.onCreateResource)).subscribe({
        next: (eventData) => {
          const newResource = eventData.value.data.onCreateResource;
          let resources = this.state.resources.slice();
          resources.map((resource, index) => {
            resource.details.map((detail, index) => {
              if (detail.images) {
                this._loadImages(detail);
              }
            })
          })
          resources.push(newResource);
          this.setState({resources: resources}, () => console.log("created resource"));
        }
      });
    } catch (err) {
      console.log('error subscribing... ')
      console.log(err);
    }
    try {
      const subscription = API.graphql(graphqlOperation(subscriptions.onDeleteResource)).subscribe({
        next: (eventData) => {
          const deletedResource = eventData.value.data.onDeleteResource;
          let resources = this.state.resources.slice();
          for (let i = 0; i < resources.length; i++){
            if (resources[i].id === deletedResource.id) {
              resources.splice(i, 1);
            }
          }
          this.setState({resources: resources}, () => {console.log("deleted resource")});
        }
      });
    } catch (err) {
      console.log('error subscribing... ')
      console.log(err);
    }
    try {
      const subscription = API.graphql(graphqlOperation(subscriptions.onUpdateResource)).subscribe({
        next: (eventData) => {
          const updatedResource = eventData.value.data.onUpdateResource;
          updatedResource.details.map((detail, index) => {
            if (detail.images) {
              this._loadImages(detail);
            }
          })
          let resources = this.state.resources.slice();
          for (let i = 0; i < resources.length; i++){
            if (resources[i].id === updatedResource.id) {
              resources.splice(i, 1);
              resources.splice(i, 0, updatedResource);
            }
          }
          this.setState({resources: resources}, () => {console.log("updated resource")});
        }
      });
    } catch (err) {
      console.log('error subscribing... ')
      console.log(err);
    }
  }

  renderResources = (resource, index) => {
    return (
      <View key={index}>
        <Button style={styles.resourceButton} onPress={this._handleResourcePress.bind(this, index)} text={resource.name}/>
        {
          (index < this.state.resources.length - 1) ?
          <HR />
          : <br/>
        }
      </View>
    )
  }

  renderResourceDetails = (resource, index) => {
    return (
      <ResourceDetailScreen key={index} hidden={this.state.resource === index ? false : true} resource={resource} images={this.state.images}/>
    )
  }

  render() {
    return (
      (this.state.sendingFeedback || this.state.loadingResources) ?
      (
        <View style={[styles.appContainer, styles.loadingContainer]}>
          <OriyaText>{this.state.loadingResources ? "Loading" : "Sending"}...</OriyaText>
          <ActivityIndicator size="large" color={Colors.electricblue} />
        </View>
      ) :
      (this.state.renderResourceDetail === false && this.state.renderFeedbackPage === false) ?
      (
        <View style={styles.appContainer}>
          <TopHeader>Resources</TopHeader>
          <View style={styles.icons}>
            <Pulse onPress={this._handlePulsePress}/>
          </View>
          <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
            <View style={styles.helpContainer}>
            {
              this.state.resources.map(this.renderResources)
            }
            </View>
          </ScrollView>
          {
            this.state.messageSent ?
            (
              <View style={styles.displayMessage}>
                <OriyaText style={{color: "#fff", fontSize: 16}}>Feedback submitted.</OriyaText>
              </View>
            ) : null
          }
        </View>
      ) :
      (this.state.renderFeedbackPage === true) ?
      (
        <View style={styles.appContainer}>
          <BackButton onPress={this._handleBackButtonPress}/>
          <FeedbackScreen handleSubmitFeedback={this._handleSubmitFeedback}/>
        </View>
      ) :
      (this.state.renderResourceDetail === true) ?
      (
        <View style={styles.appContainer}>
          <BackButton onPress={this._handleBackButtonPress}/>
          {
            this.state.resources.map(this.renderResourceDetails)
          }
        </View>
      ) :
      (
        <View style={styles.appContainer}>
          <BackButton onPress={this._handleBackButtonPress}/>
        </View>
      )
    );
  }

  displayMessage = () => {
    this.setState({messageSent: true}, () => {
      setTimeout(
        function() {
          this.setState({messageSent: false});
        }
        .bind(this),
        1500
      )
    })
  }

  _handleSubmitFeedback = async (feedback, emotion) => {
    const feedbackObject = {
      id: uuid(),
      anonymous: true,
      emotion: emotion, // Positive or Negative or Neutral
      text: feedback,
      user: null,
    }
    try {
      this.setState({ sendingFeedback: true });
      await API.graphql(graphqlOperation(mutations.createFeedback, { input: feedbackObject }));
      this.setState({ renderResourceDetail: false, renderFeedbackPage: false, sendingFeedback: false}, () => {
        this.displayMessage();
      });
    } catch (err) {
      console.log('error creating feedback... ' + JSON.stringify(err.errors));
    }
  }

  _handleResourcePress = (index) => {
    this.setState({ renderResourceDetail: true, resource: index });
  };

  _handleBackButtonPress = () => {
    this.setState({ renderResourceDetail: false, renderFeedbackPage: false, resource: null });
  }

  _handlePulsePress = () => {
    this.setState({ renderFeedbackPage: true });
  }

}

const styles = StyleSheet.create({
  appContainer: {
    paddingTop: 35,
    paddingHorizontal: 20,
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'flex-start'
  },
  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -35
  },
  keyboardViewContainer: {
    flex: .5
  },
  container: {
    flex: 1,
    marginTop: 20
  },
  contentContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  helpContainer: {
    marginTop: 15,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%'
  },
  helpLink: {
    paddingVertical: 15,
  },
  resourceText: {
    fontSize: Layout.pText,
    color: Colors.defaultText,
  },
  icons: {
    position: "absolute",
    top: 45,
    right: 15,
    display: 'flex',
    flexDirection: 'row'
  },
  backIcon: {
    flexDirection: "row",
    alignItems: "center",
    top: 45,
    left: 15,
    justifyContent: "space-around",
    margin: 10
  },
  resourceButton: {
    width: "100%"
  },
  displayMessage: {
    backgroundColor: Colors.header,
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    paddingBottom: 10,
    display: 'flex',
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0
  }
});
