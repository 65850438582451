import React from 'react';
import { View } from 'react-native';
import Colors from '../constants/Colors';

export const HR = (props) => (
  <View
    style={{
      marginVertical: '1em',
      borderBottomColor: Colors.hr,
      borderBottomWidth: '1px',
    }}
  />
)
