// eslint-disable
// this is an auto generated file. This will be overwritten

export const onCreateResource = `subscription OnCreateResource {
  onCreateResource {
    id
    name
    details {
      id
      name
      displayName
      value
      images {
        id
        uri
        caption
      }
      links {
        id
        url
      }
      html
      index
    }
    createdAt
    updatedAt
    active
  }
}
`;
export const onUpdateResource = `subscription OnUpdateResource {
  onUpdateResource {
    id
    name
    details {
      id
      name
      displayName
      value
      images {
        id
        uri
        caption
      }
      links {
        id
        url
      }
      html
      index
    }
    createdAt
    updatedAt
    active
  }
}
`;
export const onDeleteResource = `subscription OnDeleteResource {
  onDeleteResource {
    id
    name
    details {
      id
      name
      displayName
      value
      images {
        id
        uri
        caption
      }
      links {
        id
        url
      }
      html
      index
    }
    createdAt
    updatedAt
    active
  }
}
`;
export const onCreateFeedback = `subscription OnCreateFeedback {
  onCreateFeedback {
    id
    anonymous
    emotion
    text
    user
  }
}
`;
export const onUpdateFeedback = `subscription OnUpdateFeedback {
  onUpdateFeedback {
    id
    anonymous
    emotion
    text
    user
  }
}
`;
export const onDeleteFeedback = `subscription OnDeleteFeedback {
  onDeleteFeedback {
    id
    anonymous
    emotion
    text
    user
  }
}
`;
