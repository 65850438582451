import React from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  Image,
  ActivityIndicator
} from 'react-native';
import Colors from '../constants/Colors';
import { OriyaText } from '../components/StyledText';

export default class CustomImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded: false,
      imageUri: this.props.imageUri,
      failed: false,
      onError: this.props.onError
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.imageUri) {
      this.setState({imageUri: nextProps.imageUri});
    }
  }

  _onError = (err) => {
    console.log(err);
    this.setState({ failed: true });
  }

  render() {
    let { imageUri } = this.state;
    return (
      (this.state.imageLoaded ?
      (
        <View>
          <Image
            source={{uri : imageUri, cache: 'force-cache'}}
            onError={this._onError}
            onLoad={() => { this.setState({imageLoaded: true}) }}
            style={{
              flex: 1,
              alignSelf: 'stretch',
              height: Dimensions.get('window').height / 3,
              width: Dimensions.get('window').width,
              marginBottom: 10}}
            resizeMode='contain'/>
          <OriyaText style={styles.caption}>{this.props.imageCaption}</OriyaText>
        </View>
      ) : (this.state.failed) ?
      (
        <View>
          <OriyaText>Image does not exist</OriyaText>
        </View>
      ) : (
        <View>
          <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height / 3
          }}>
            <ActivityIndicator size="large" color={Colors.electricblue} />
          </View>
          <View>
            <Image
              source={{uri : imageUri, cache: 'force-cache'}}
              onError={this._onError}
              onLoad={() => { this.setState({imageLoaded: true}) }}
              style={{
                flex: 1,
                alignSelf: 'stretch',
                height: Dimensions.get('window').height / 3,
                width: Dimensions.get('window').width,
                marginBottom: 10}}
              resizeMode='contain'/>
            <OriyaText style={styles.caption}>{this.props.imageCaption}</OriyaText>
          </View>
        </View>
      )
    )
    )
  }
}

const styles = StyleSheet.create({
  caption: {
    textAlign: 'center',
    padding: 10,
    paddingTop: 0

  }
});
