export default {
  buttonWidth: 275,
  buttonHeight: 45,
  pText: 18,
  h2Text: 24,
  h1Text: 30,
  contentPaddingTop: '1em',
  contentPaddingTopL: '2em',
  contentPaddingTopXL: '3em',
  h3Text: 20
};
