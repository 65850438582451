import React from 'react';
import './App.css';
import { StyleSheet, View, Platform, StatusBar } from 'react-native';
import HomeScreen from './screens/HomeScreen';
import Auth from '@aws-amplify/auth';
import Amplify from 'aws-amplify';
import awsconfig from './aws-configs';

// retrieve temporary AWS credentials and sign requests
Amplify.configure(awsconfig);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    await Auth.currentAuthenticatedUser()
    .then(user => {
      console.log(user)
      this.setState({userToken: user.signInUserSession.accessToken.jwtToken})
    })
    .catch(err => {
      console.log("error getting authenticated user")
      console.log(err);
      const username = "guest";
      const password = "Yn*QnKy3EC";
      Auth.signIn(username, password);
    })
  }

  render() {
      return (
        <View style={styles.container}>
          {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
          <HomeScreen />
        </View>
      );
  }
}

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute'
  },
});
