import React from 'react';
import {
  TouchableOpacity,
} from 'react-native';
import Colors from '../constants/Colors';
import { Icon } from '../components/Icon';

export default class Smile extends React.Component {
  render() {
    this.color = this.props.selected ? Colors.smile : 'white';
    return (
      <TouchableOpacity style={{ marginHorizontal: '1em', padding: '.8em', borderRadius: '3em', overflow: 'hidden', backgroundColor: this.color }} onPress={this.props.onPress}>
        <Icon style={{backgroundColor: this.color}} name={this.props.name} size="2x" color={this.props.selected ? Colors.pulse : Colors.defaultTextLighter}></Icon>
      </TouchableOpacity>
    )
  }
}
