// eslint-disable
// this is an auto generated file. This will be overwritten

export const createResource = `mutation CreateResource($input: CreateResourceInput!) {
  createResource(input: $input) {
    id
    name
    details {
      id
      name
      displayName
      value
      images {
        id
        uri
        caption
      }
      links {
        id
        url
      }
      html
      index
    }
    createdAt
    updatedAt
    active
  }
}
`;
export const updateResource = `mutation UpdateResource($input: UpdateResourceInput!) {
  updateResource(input: $input) {
    id
    name
    details {
      id
      name
      displayName
      value
      images {
        id
        uri
        caption
      }
      links {
        id
        url
      }
      html
      index
    }
    createdAt
    updatedAt
    active
  }
}
`;
export const deleteResource = `mutation DeleteResource($input: DeleteResourceInput!) {
  deleteResource(input: $input) {
    id
    name
    details {
      id
      name
      displayName
      value
      images {
        id
        uri
        caption
      }
      links {
        id
        url
      }
      html
      index
    }
    createdAt
    updatedAt
    active
  }
}
`;
export const createFeedback = `mutation CreateFeedback($input: CreateFeedbackInput!) {
  createFeedback(input: $input) {
    id
    anonymous
    emotion
    text
    user
  }
}
`;
export const updateFeedback = `mutation UpdateFeedback($input: UpdateFeedbackInput!) {
  updateFeedback(input: $input) {
    id
    anonymous
    emotion
    text
    user
  }
}
`;
export const deleteFeedback = `mutation DeleteFeedback($input: DeleteFeedbackInput!) {
  deleteFeedback(input: $input) {
    id
    anonymous
    emotion
    text
    user
  }
}
`;
export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    username
    phoneNumber
    givenName
    familyName
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    username
    phoneNumber
    givenName
    familyName
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    username
    phoneNumber
    givenName
    familyName
  }
}
`;
