import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as all from '@fortawesome/free-solid-svg-icons';

export class Icon extends React.Component {
  render() {
    const {name, ...other} = this.props;
    const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)
    const icon = all[`fa${nameCapitalized}`];
    return <FontAwesomeIcon icon={icon} {...other} />
  }
}
